<template>
    <div class="custom-select dropdown" :class="{'is-active': opened}">
        <div class="dropdown-trigger">
            <button
                v-if="forceWidth"
                class="button" aria-haspopup="true"
                :id="computeUniqueId"
                :aria-controls="'dm' + name"
                @click="opened = !opened"
                :style="setStyle()"
            >
                <span>{{ btnTitle }}</span>
                <span class="icon is-small">
                    <i class="fas fa-angle-down" aria-hidden="true" aria-label="Dropdown"></i>
                </span>
            </button>

            <button
                v-else
                class="button" aria-haspopup="true"
                :aria-controls="'dm' + name"
                :id="computeUniqueId"
                @click="opened = !opened"
            >
                <span>{{ btnTitle }}</span>
                <span class="icon is-small">
                    <i class="fas fa-angle-down" aria-hidden="true" aria-label="Dropdown"></i>
                </span>
            </button>

        </div>
        <div class="dropdown-menu" :id="computeUniqueId" :style="[dropdownHide ? 'display:none;' : '']" role="menu" v-if="opened">
            <div class="dropdown-content">
                <div class="dropdown-item" :id="name + '-' + idFormat(item.name)"
                     v-for="(item, k) in items" :key="k"
                     @click="select(item.value)"
                     :class="{'is-active': selectedValue === item.value}"
                >
                    {{ typeof item.name !== 'undefined' ? item.name : item.value }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { computed, ref, watch } from 'vue'
    import { idFormat } from "@/helpers/filter";
    import { v4 as uuidv4 } from "uuid";

    export default {
        name: 'custom-select',
        model: {
            prop: 'value',
            event: 'selected'
        },
        setup(props, {emit}) {
            const opened = ref(false);
            const selectedValue = ref(props.defaultSelected); // Initialize with defaultSelected
            const btnTitle = computed(() => {
                if (selectedValue.value !== null) {
                    const defaultItem = props.items.find(item => item.value === selectedValue.value);
                    if (defaultItem) {
                        return typeof defaultItem.name !== 'undefined' ? defaultItem.name : defaultItem.value;
                    }
                }
                if (!props.value || !props.items || !props.items.length) {
                    return props.placeholder || 'Select';
                }
                const filteredItems = props.items.filter(item => item.value == props.value);
                if (!filteredItems.length) {
                    return props.placeholder || 'Select';
                }

                return typeof filteredItems[0].name !== 'undefined' ? filteredItems[0].name : filteredItems[0].value;
            });

            // Generate a unique ID for each instance
            const computeUniqueId = computed(() => {
                return 'dm' + props.name + '-' + props.uniqueId;
            });

            const select = value => {
                opened.value = false;
                selectedValue.value = value; // Update the selected value
                emit('update:value', value);
            }

            watch(() => props.value, (newVal) => {
                selectedValue.value = newVal;
            });

            const outsideClick = () => opened.value = false;

            return {select, outsideClick, opened, btnTitle, idFormat, selectedValue, computeUniqueId}
        },
        methods: {
            setStyle() {
                if (this.forceWidth) {
                    let style = "width: " + this.forceWidth + "px;";
                    return style;
                }
            }
        },
        props: {
            name: String,
            uniqueId: String,
            value: {
                type: [String, Number],
            },
            defaultSelected: {
                type: [String, Number], // The type of the selected option
                default: null, // Default value for the pre-selected option
            },
            placeholder: {
                type: String,
                required: true
            },
            items: {
                type: Array,
                required: true
            },
            forceWidth: Number,
            dropdownHide: Boolean
        }
    };
</script>

<style lang="scss">
    @import "../../assets/scss/_variables.scss";
    .custom-select {
        border: 2px solid $blue--dark;
        border-radius: $border-radius;
        @media screen and (max-width: 768px) {
            max-width: 100%;
        }
        button {
            justify-content: space-between;
            height: 55px;
        }

        button, button:hover, button:focus, button:active {
            background-color: white;
            color: black;
        }

        button, .dropdown-trigger, .dropdown-menu {
            width: 100%;
            min-width: 8rem;
            @media screen and (max-width: 768px) {
                max-width: 100%;
            }
        }

        .dropdown-content {
            max-height: 300px;
            overflow-y: auto;

            .dropdown-item {
//                white-space: nowrap;
                cursor: pointer;

                &.is-active {
                    background-color: #DDD;
                }
                &:hover, &:focus, &:active {
                    background-color: #eee;
                }
            }
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar {
                width: 10px;
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: $blue--med;
            }
        }
    }
</style>
