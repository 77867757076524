<template>
    <!-- Component: molecules/card-tools -->
    <div class="card__tools" v-if="contact || agency || position || program || programcategory || vendor || contract || procurement || document" :class="[ size, 'view-' + view, customClass ]">
        <Tooltip :tooltipText="isBookmarked ? 'Remove this Bookmark' : 'Bookmark this ' + typeTitle" :position="'right'">
            <a href="#" class="card__tools__bookmark has-tooltip has-tooltip--no-underline"
                @click.prevent="toggleBookmark"
                :class="{'card__tools__bookmark--checked': isBookmarked}"
            >
                <img src="@/assets/svg/bookmark--checked.svg" alt="Bookmarked" v-if="isBookmarked"/>
                <img src="@/assets/svg/bookmark.svg" alt="Bookmark" v-else/>
                <span v-if="view == 'full'">Bookmark this {{ typeTitle }}</span>

            </a>
        </Tooltip>

        <Tooltip :tooltipText="typeTitle + ' Notes'" :position="'right'">
            <a href="#" class="card__tools__notes has-tooltip has-tooltip--no-underline" @click.prevent.stop="showNotes = true" v-if="contentId">
                <img src="@/assets/svg/notes.svg" alt="Notes"/>{{ notesCount || '' }}
                <span v-if="view == 'full'">{{ typeTitle }} Note{{notesCount != 1 ? 's' : '' }}</span>
            </a>
        </Tooltip>
        <Tooltip v-if="isAdmin" :tooltipText="typeTitle + ' Notifications'" :position="'right'">
            <a href="#" class="card__tools__notification has-tooltip has-tooltip--no-underline"
               @click.prevent="toggleNotification" v-if="contentId">
                <img class="notifications"  src="@/assets/svg/notification--checked.svg" alt="Notifications" v-if="isNotificationSet"/>
                <img class="notifications"  src="@/assets/svg/notification.svg" alt="Notifications" v-else/>
                <span v-if="view == 'full'">{{ typeTitle }} Notification</span>
            </a>
        </Tooltip>
        <contact-notes-modal
            v-if="showNotes"
            @close="showNotes = false"
            @created="newNoteCreated"
            :key="contentId"
            :contact="contact"
            :agency="agency"
            :position="position"
            :program="program"
            :programcategory="programcategory"
            :vendor="vendor"
            :contract="contract"
            :procurement="procurement"
            :document="document"
        >
            <notes-list :uniqueId="uniqueId" :related-content-id="contentId"></notes-list>
        </contact-notes-modal>

    </div>
    <!-- End Component: molecules/card-tools -->
</template>

<script>
    import { computed, onMounted, ref, toRefs, watch } from "vue";
    import { useStore } from "vuex";
    import { ACTION_TYPES } from "@/constants";
    import NotesList from "./notes-list";
    import ContactNotesModal from "./contact-notes-modal";
    import RequestHandler from "@/handler/RequestHandler";
    import { getContentId, getLocationId } from "@/helpers/objects";
    import Tooltip from "./tooltip";
    import { v4 as uuidv4 } from "uuid";

    export default {
        name: 'CardTools',
        props: [
            'contact',
            'agency',
            'position',
            'size',
            'program',
            'programcategory',
            'vendor',
            'contract',
            'procurement',
            'document',
            'view',
            'customClass'
        ],
        components: {
            NotesList,
            ContactNotesModal,
            Tooltip
        },
        setup(props) {
            const showNotes = ref(false);
            const notesCount = ref(0);
            const locationId = computed(() => getLocationId(props.contact, props.position, props.agency, props.program, props.programcategory, props.vendor, props.contract, props.procurement, props.document));
            const contentId = computed(() => getContentId(props.contact, props.position, props.agency, props.program, props.programcategory, props.vendor, props.contract, props.procurement, props.document));
            const store = useStore();
            const isBookmarkedIdsLoaded = computed(() => store.getters.isBookmarkedIdsLoaded);
            const isNotificationIdsLoaded = computed(() => store.getters.isNotificationIdsLoaded);

            const isAdmin = computed(() => store.getters.user.isAdmin);
            
            const isBookmarked = computed(
                () => locationId.value && store.getters.isBookmarkedIdsLoaded
                    && store.getters.bookmarkedIds.indexOf(locationId.value) !== -1
            );
            const isNotificationSet = computed(
                () => contentId.value && store.getters.isNotificationIdsLoaded
                    && store.getters.notificationIds.indexOf(contentId.value) !== -1
            );

            const toggleBookmark = () => {
                if (isBookmarked.value) {
                    store.dispatch(ACTION_TYPES.removeBookmarkId, locationId.value);
                } else {
                    store.dispatch(ACTION_TYPES.addBookmarkId, locationId.value);
                }
            };

            const toggleNotification = () => {
                if (isNotificationSet.value) {
                    store.dispatch(ACTION_TYPES.removeNotificationId, contentId.value);
                } else {
                    store.dispatch(ACTION_TYPES.addNotificationId, contentId.value);
                }
            };

            const loadNotesCount = () => {
                if (!contentId.value) {
                    return;
                }
                RequestHandler.loadNotedObjectsCount([contentId.value])
                    .then(count => notesCount.value = count || 0);
            };

            const newNoteCreated = () => {
                showNotes.value = false;
                notesCount.value = notesCount.value + 1;
            };

            watch(contentId, loadNotesCount);
            onMounted(() => {
                if (!isBookmarkedIdsLoaded.value) {
                    store.dispatch(ACTION_TYPES.loadBookmarkIds);
                }
                if (!isNotificationIdsLoaded.value) {
                    store.dispatch(ACTION_TYPES.loadNotificationIds);
                }
                loadNotesCount();
            });

            let typeTitle = '';
            if (props.agency) {
                typeTitle = 'Agency';
            } else if (props.position) {
                typeTitle = 'Position';
            } else if (props.contact) {
                typeTitle = 'Contact';
            } else if (props.program) {
                typeTitle = 'Program';
            } else if (props.programcategory) {
                typeTitle = 'Program Category';
            } else if (props.vendor) {
                typeTitle = 'Vendor';
            } else if (props.contract) {
                typeTitle = 'Contract';
            } else if (props.procurement) {
                typeTitle = 'Procurement';
            } else if (props.document) {
                typeTitle = 'Document';
            }

            // Generate a unique ID for each instance
            const uniqueId = computed(() => {
                return uuidv4();
            });

            return {isAdmin, uniqueId, isBookmarked, isNotificationSet, toggleNotification, newNoteCreated, toggleBookmark, showNotes, contentId, notesCount, typeTitle};
        }
    }
</script>


<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
    @import "../../assets/scss/partials/_cards--info.scss";

    @include block('card') {
        @include element('tools') {
            .notifications{
                width: 36px;
                align-self: center;
            }
            @include element('business-card') {
                font-size: 40px;
            }
            &.budget {
                width: 100%;
                display: block;
                text-align: center;
                background-color: transparent;

                .card__tools__bookmark, .card__tools__notification, .card__tools__notes {
                    display: inline-block;
                    margin: 30px 10px 0 10px;
                    color: $blue--dark;
                    text-decoration: none;
                    span {
                        position: relative;
                        top: -10px;
                        margin-left: 10px;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    #contact-notes-modal {
        text-align: left !important;
    }

</style>
