<template>
    <!-- Component: organisms/recent-news-block -->
    <general-container :customClass="customClass" :title="title ? title: ''" :isLocked="!items || items.length === 0"  v-if="items && items.length !== 0">

        <info-block-news
            v-if="items && items.length"
            v-for="item in items"
            :title="item.title"
            :desc="item.content"
            :url="item.link"
            :date="item.published"
        />
        <div v-if="!items || items.length === 0" class="no-data">
            <p class="no-data__icon">🧐</p>
            <p class="no-data__title">Hmmmm...</p>
            <p>It looks like there is no recent news about this agency. We'll keep checking and put the news here when it drops!</p>
        </div>

        <read-more-link v-if="contentId && items && items.length" :contents="readMore" :url="'/recent-news?id=' + contentId"></read-more-link>

    </general-container>
    <!-- End Component: organisms/recent-news-block -->
</template>

<script>
    import GeneralContainer from "./general-container"
    import InfoBlockNews from "./info-block--news"
    import ReadMoreLink from "./read-more-link"

    export default {
        name: 'RecentNewsBlock',
        props: [ 'items', 'contentId', 'title', ],
        components: {
            GeneralContainer,
            InfoBlockNews,
            ReadMoreLink
        },
        data() {
            return {
                customClass: "recent-news-block",
                readMore: "See All Recent News",
                title: "In The News"
            }
        },
         mounted() {
            // aply negative keyword filter
            const negativeFilter= ['winning numbers']
            let articles = this.$props.items
            articles.slice().reverse().forEach(function(item, index, object) {
                let title = item['title'].toLowerCase()
                negativeFilter.forEach(filteredPhrase => {
                    if (title.includes(filteredPhrase)){
                        // remove item
                        articles.splice(object.length - 1 - index, 1);
                    }
                })
            });
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
/*
    .media + .media {
        border-top: 1px solid $blue--med;
    }
*/
    @include block('no-data') {
        font-weight: 600;
        font-family: $header;
        font-size: 1.25rem;
        text-align: center;
        max-width: 800px;
        margin: 0 auto;
        @include element('title') {
            font-size: 1.5rem;
            margin-bottom: 5px;
        }
        @include element('icon') {
            font-size: 3.5rem;
            margin-bottom: -15px;
        }
    }
</style>

