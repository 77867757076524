<template>
    <!-- Component: molecules/bookmark-note-icons -->
    <div class="card__tools2 card__tools" :class="customClass" v-if="contact || agency || position || program || programcategory || vendor || contract || procurement" >

        <Tooltip :tooltipText="isBookmarked ? 'Remove this Bookmark' : 'Bookmark this ' + typeTitle" :position="'right'">
            <a href="#" class="card__tools__bookmark has-tooltip has-tooltip--no-underline"
                @click.prevent="toggleBookmark"
                :class="{'card__tools__bookmark--checked': isBookmarked}"
            >
                <img src="@/assets/svg/bookmark--checked.svg" alt="Bookmarked" v-if="isBookmarked"/>
                <img src="@/assets/svg/bookmark.svg" alt="Bookmark" v-else/>
            </a>
        </Tooltip>

        <Tooltip :tooltipText="typeTitle + ' Notes'" :position="'right'">
            <a href="#" class="card__tools__notes has-tooltip has-tooltip--no-underline" @click.prevent.stop="showNotes = true" v-if="contentId">
                <img src="@/assets/svg/notes.svg" alt="Notes"/>{{ notesCount || '' }}
            </a>
        </Tooltip>

        <Tooltip v-if="isAdmin" :tooltipText="typeTitle + ' Notification'" :position="'right'">
            <a href="#" class="card__tools__notification has-tooltip has-tooltip--no-underline" @click.prevent="toggleNotification" v-if="contentId">
                <img class="notifications"  src="@/assets/svg/notification--checked.svg" alt="Notifications" v-if="isNotificationSet"/>
                <img class="notifications"  src="@/assets/svg/notification.svg" alt="Notifications" v-else/>
            </a>
        </Tooltip>

        <contact-notes-modal
            v-if="showNotes"
            @close="showNotes = false"
            @created="newNoteCreated"
            :key="contentId"
            :contact="contact"
            :agency="agency"
            :position="position"
            :program="program"
            :programcategory="programcategory"
            :vendor="vendor"
            :contract="contract"
            :procurement="procurement"
        >
            <notes-list :uniqueId="uniqueId" :related-content-id="contentId"></notes-list>
        </contact-notes-modal>
    </div>
    <!-- End Component: molecules/bookmark-note-icons -->
</template>

<script>
    import { computed, onMounted, ref, toRefs, watch } from "vue";
    import { useStore } from "vuex";
    import { ACTION_TYPES } from "@/constants";
    import NotesList from "./notes-list";
    import ContactNotesModal from "./contact-notes-modal";
    import RequestHandler from "@/handler/RequestHandler";
    import { getContentId, getLocationId } from "@/helpers/objects";
    import Tooltip from "./tooltip";
    import { v4 as uuidv4 } from "uuid";

    export default {
        name: 'BookmarkNoteIcons',
        props: {
            agencyObject: Object,
            agency: Object,
            contact: Object,
            position: Object,
            program: Object,
            programcategory: Object,
            vendor: Object,
            contract: Object,
            procurement: Object,
            customClass: String
        },
        components: {
            NotesList,
            ContactNotesModal,
            Tooltip
        },
        setup(props) {

            // Generate a unique ID for each instance
            const uniqueId = computed(() => {
                return uuidv4();
            });

            const showNotes = ref(false);
            const notesCount = ref(0);
            const locationId = computed(() => getLocationId(props.contact, props.position, props.agency, props.program, props.programcategory, props.vendor, props.contract, props.procurement));
            const contentId = computed(() => getContentId(props.contact, props.position, props.agency, props.program, props.programcategory, props.vendor, props.contract, props.procurement));
            const store = useStore();
            const isBookmarkedIdsLoaded = computed(() => store.getters.isBookmarkedIdsLoaded);
            const isNotificationIdsLoaded = computed(() => store.getters.isNotificationIdsLoaded);

            const isAdmin = computed(() => store.getters.user.isAdmin);

            const isBookmarked = computed(
                () => locationId.value && store.getters.isBookmarkedIdsLoaded
                    && store.getters.bookmarkedIds.indexOf(locationId.value) !== -1
            );
            const isNotificationSet = computed(
                () => contentId.value && store.getters.isNotificationIdsLoaded
                    && store.getters.notificationIds.indexOf(contentId.value) !== -1
            );

            const toggleBookmark = () => {
                if (isBookmarked.value) {
                    store.dispatch(ACTION_TYPES.removeBookmarkId, locationId.value);
                } else {
                    store.dispatch(ACTION_TYPES.addBookmarkId, locationId.value);
                }
            };

            const toggleNotification = () => {
                if (isNotificationSet.value) {
                    store.dispatch(ACTION_TYPES.removeNotificationId, contentId.value);
                } else {
                    store.dispatch(ACTION_TYPES.addNotificationId, contentId.value);
                }
            };

            const loadNotesCount = () => {
                if (!contentId.value) {
                    return;
                }
                RequestHandler.loadNotedObjectsCount([contentId.value])
                    .then(count => notesCount.value = count || 0);
            };

            const newNoteCreated = () => {
                showNotes.value = false;
                notesCount.value = notesCount.value + 1;
            };

            watch(contentId, loadNotesCount);
            onMounted(() => {
                if (!isBookmarkedIdsLoaded.value) {
                    store.dispatch(ACTION_TYPES.loadBookmarkIds);
                }
                if (!isNotificationIdsLoaded.value) {
                    store.dispatch(ACTION_TYPES.loadNotificationIds);
                }
                loadNotesCount();
            });
            let typeTitle = '';
            if (props.agency) {
                typeTitle = 'Agency';
            } else if (props.position) {
                typeTitle = 'Position';
            } else if (props.contact) {
                typeTitle = 'Contact';
            } else if (props.program) {
                typeTitle = 'Program';
            } else if (props.programcategory) {
                typeTitle = 'Program Category';
            } else if (props.vendor) {
                typeTitle = 'Vendor';
            } else if (props.contract) {
                typeTitle = 'Contract';
            } else if (props.procurement) {
                typeTitle = 'Procurement';
            }

            return {isAdmin, uniqueId, isNotificationSet, toggleNotification, isBookmarked, newNoteCreated, toggleBookmark, showNotes, contentId, notesCount, typeTitle};

        }
    }
</script>

<style lang="scss" scoped>
    .card__tools2 {
        text-decoration: none;
        height: 100%;
    }
    #contact-notes-modal {
        text-align: left !important;
    }
    .notifications{
        width: 42px;
        align-self: center;
    }
    .card__tools.icon-only {
        background-color: transparent;
        width: 20px;
        float: left;
        display: block;
        margin-right: 10px;
    }
</style>
