<template>
    <general-container
        :customClass="'column procurements-table-column'"
        :titleClass="'has-text-centered'"
        :isLocked="false"
        :title="'Employees'"
    >
        <div class="procurementsTable tbl responsive-table-800">
            <table class="zebra" id="turnover-table">
                <thead>
                <tr>
                    <th class="clickable mw-110"  scope="col">
                        Name
                    </th>
                    <th class="clickable mw-110"  scope="col">
                        Position
                    </th>
                    <th  class="clickable mw-110"  scope="col">
                        Date
                    </th>
                </tr>
                </thead>
                <tbody  v-if="bb.length > 0" >
                <template v-for="(employee) in bb" >
                    <tr >
                        <td >
                            <a :href="employee.url">
                            {{employee.name}}
                            </a>
                        </td>
                        <td   v-if="direction == 'to'">
                            {{employee.toPositionName}}
                        </td>

                        <td v-else>
                            {{employee.fromPositionName}}
                        </td>

                        <td>
                            {{employee.updatedAt}}
                        </td>
                    </tr>
                </template>
                </tbody>
                <tbody v-else>
                <td colspan="3">
                    <h5 class="search-info"> "No results found"</h5>
                </td>
                </tbody>
            </table>
        </div>
    </general-container>
</template>

<script>

import RequestHandler from "@/handler/RequestHandler";
import {ref, watch} from "vue";
import GeneralContainer from "./general-container.vue";

export default {
    components: {GeneralContainer},
    props: [
        'direction',
        'agencyName'
    ],
    setup(props) {
        const pageLimit = ref(50)
        const page = ref(0)
        const type = ref('employment_update')
        const subtree = ref(0)
        const zz =ref([])
        const bb = ref([])
        const loadResul = () => {
            RequestHandler.loadAllRecentUpdates(
                pageLimit.value,
                100,
                type.value,
                subtree.value
            ).then(response => {
                zz.value = response.ContentList.list;
            }).catch((error) => {
                console.error(error);
            });
            if (props.direction == 'to') {
                bb.value = zz.value.filter(item => item.toAgencyName === props.agencyName)
            } else if (props.direction == 'from') {
                bb.value = zz.value.filter(item => item.fromAgencyName === props.agencyName)
            } else {
                console.log('wrong direction')
            }
        }
        watch(props, (now) => {
            loadResul();
        });
        return {
            pageLimit,
            page,
            zz,
            bb,
            type,
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/mixins/_bemify.scss";

.tbl thead th {
    color: #fff;
    position: -webkit-sticky;
    position: sticky;
}
@media screen and (max-width: 800px) {
    .procurementsTable table tbody tr td.title-cell {
        padding-bottom: 0px;
        .title__bookmark {
            display: initial;
            .card__tools.icon-only {
                display: contents;
                // if you want to style anything below this level you need to do it in the _required.scss sheet
            }
        }
        .title__content {
            display: inherit;
            position: relative;
            top: -9px;
        }
    }
}
</style>
