<template>

    <vendor-flyout
        ref="vendorFlyoutRef"
        v-bind="vendorProps"
    />

    <contract-flyout
        ref="contractFlyoutRef"
        v-bind="contractProps"
        :vendor="contractProps && contractProps.vendor && contractProps.vendor.VendorRef ? contractProps.vendor.VendorRef : null"
        :agency="contractProps && contractProps.agency && contractProps.agency.AgencyRef ? contractProps.agency.AgencyRef : null"
    ></contract-flyout>

    <!-- Page: pages/agency -->
    <div class="page-container agency-page">

        <br/>

        <breadcrumbs :items="path"/>

        <div class="container">
            <alert/>
        </div>

        <hr/>

        <div class="container" style="display: flow-root;" v-if="user">
            <userTools/>
        </div>

        <br/>

        <div class="container">
            <agency-info-card v-bind="$props"/>
        </div>

        <br>
            <turnover-chart-individual :agency-id="locationId"></turnover-chart-individual>
        <br>

        <div class="container">
            <div class="columns">
                <div class="column max-w-100">
                    <general-container title="Organizational Chart" custom-class="org-chart-container">
                        <contacts-tree-chart :agency-id="contentId" :agency-location-id="locationId" :key="contentId"/>
                    </general-container>
                </div>
            </div>
        </div>
        <br>
        <template v-if="contentId !== 1824561">
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <recent-news-block :items="rss" :agencyContentId="contentId" :title="'In The News'"/>
                    </div>
                    <div class="column is-one-third" v-if="currentBudgetRequest || currentIssueAndDollar || currentAmendedBudget1 || currentAmendedBudget2 || currentAmendedBudget3 || currentAmendedBudget4 || currentAmendedBudget5 || priorBudgetRequest || priorIssueAndDollar || priorAmendedBudget1 || priorAmendedBudget2 || priorAmendedBudget3 || priorAmendedBudget4 || priorAmendedBudget5">
                        <legislative-budget-requests
                            :currentBudgetRequest="currentBudgetRequest"
                            :currentIssueAndDollar="currentIssueAndDollar"
                            :currentAmendedBudget1="currentAmendedBudget1"
                            :currentAmendedBudget2="currentAmendedBudget2"
                            :currentAmendedBudget3="currentAmendedBudget3"
                            :currentAmendedBudget4="currentAmendedBudget4"
                            :currentAmendedBudget5="currentAmendedBudget5"
                            :priorBudgetRequest="priorBudgetRequest"
                            :priorIssueAndDollar="priorIssueAndDollar"
                            :priorAmendedBudget1="priorAmendedBudget1"
                            :priorAmendedBudget2="priorAmendedBudget2"
                            :priorAmendedBudget3="priorAmendedBudget3"
                            :priorAmendedBudget4="priorAmendedBudget4"
                            :priorAmendedBudget5="priorAmendedBudget5"
                            ></legislative-budget-requests>
                    </div>
                </div>
                <div v-if="contentId == 156430">
                    <recent-news-rss :title="'Agency Press Releases'"/>
                </div>
            </div>

            <br/>

            <div class="container">
                <div class="columns multi-cols">
                    <div class="column">
                        <agency-budget-block :agency-location-id="locationId" :agency-id="contentId"></agency-budget-block>
                    </div>
                </div>
            </div>

            <br>
        </template>

        <documents-table
            :agency-id="locationId.toString()"
            :not-on-agency-page = false
        />

        <template v-if="transparencyId">
            <div class="container break-768">
                <div class="columns">
                    <div class="column">
                        <general-container
                            title="Key Leadership"
                            :isLocked="false"
                            :customClass="'key-leadership-container'"
                        >
                            <contact-line
                                v-for="leader in leadership"
                                :name="getContactName(leader)"
                                :agency="leader.position.agency.name"
                                :role="leader.position.name + (leader.positionRole ? ' (' + leader.positionRole + ')' : '')"
                                :url="leader.url"
                                :agencyUrl="url"
                                :roleUrl="leader.position.url"
                                :leadership="leader.agencyHead"
                                :key="leader.position.name"
                                :imagePath="leader.photo ? leader.photo.url : null"
                            />
                            <button-link :href="'/agency-contacts?agencyId=' + locationId" target="_self" contents="See All Agency Contacts"/>
                        </general-container>
                    </div>
                    <div class="column max-w-100">
                        <interested-in-block
                            :title="'Recent position changes...'"
                            :num="2"
                            :type="'employment_update'"
                            :subtree="locationId"
                        >
                        </interested-in-block>
                    </div>
                </div>
            </div>
        </template>

        <div class="container contracts-container" v-if="contentId">
            <div class="columns">
                <div class="column">
                    <procurements-table-short
                        :uniqueId="uniqueId"
                        :content-id="contentId"
                        :location-id="locationId"
                        title="Agency Bids"
                    />
                </div>
            </div>
        </div>

        <div class="container contracts-container" v-if="contentId">
            <div class="columns">
                <div class="column">
                    <contracts-table
                        :uniqueId="uniqueId"
                        :key="contentId"
                        :agency-id-only="contentId"
                        :search-agency="false"
                        :search-domain="false"
                        :search-type="true"
                        :search-fiscal="true"
                        title="Agency Contracts"
                        :subflyout="true"
                        :vendorshow="true"
                    />
                </div>
            </div>
        </div>

    </div>
    <!-- End Page: pages/agency -->
</template>

<script>
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "./breadcrumbs";
    import UserTools from "@/alivue/components/user-tools";
    import AgencyInfoCard from "./agency-info-card";
    import ContactLine from "@/alivue/components/contact-line";
    import AgencyBudgetBlock from "./agency-budget-block";
    import useUser from "@/helpers/useUser";
    import ButtonLink from "./button-link";
    import GeneralContainer from "./general-container";
    import SearchBlock from "./search-block";
    import RecentNewsBlock from "./recent-news-block";
    import { computed, ref, watch } from "vue";
    import { useStore } from "vuex";
    import ContactsTreeChart from "./contacts-tree-chart";
    import ContractsTable from "./contracts-table";
    import InterestedInBlock from '@/alivue/components/interested-in-block.vue';
    import { contactFullName } from "@/helpers/objects";
    import LegislativeBudgetRequests from "./legislative-budget-requests";
    import Preloader from "./preloader.vue";
    import ProcurementsTableShort from "./procurements-table-short.vue";
    import RecentNewsRss from "./recent-news-rss.vue";
    import DocumentsTable from "./documents-table.vue";
    import { useEventsBus } from "@/helpers/eventBus";
    import VendorFlyout from "./vendor-flyout";
    import TurnoverChart from "./turnover-chart.vue";
    import TurnoverChartIndividual from "./turnover-chart-individual.vue";
    import ContractFlyout from "./contract-flyout";
    import { v4 as uuidv4 } from "uuid";

    export default {
        components: {
            TurnoverChartIndividual,
            TurnoverChart,
            DocumentsTable,
            RecentNewsRss,
            ProcurementsTableShort,
            Preloader,
            ContractsTable,
            ContactsTreeChart,
            Alert,
            UserTools,
            Breadcrumbs,
            AgencyInfoCard,
            AgencyBudgetBlock,
            ContactLine,
            ButtonLink,
            GeneralContainer,
            SearchBlock,
            RecentNewsBlock,
            InterestedInBlock,
            LegislativeBudgetRequests,
            VendorFlyout,
            ContractFlyout,
        },
        props: {
            contentId: Number,
            locationId: Number,
            name: String,
            type: String,
            _url: String,
            location: Object,
            url: Object,
            purpose: String,
            readMoreLink: Object,
            logo: Object,
            leadership: Object,
            acronym: String,
            linkedin: String,
            twitter: String,
            facebook: String,
            youtube: String,
            rss: Array,
            vision: String,
            info: String,
            goals: String,
            objectives: String,
            totalEmployees: String,
            mission: String,
            isSubAgency: Boolean,
            parentAgencyName: String,
            parentAgencyAcronym: String,
            parentAgencyUrl: String,
            transparencyId: String,
            currentBudgetRequest: String,
            currentIssueAndDollar: String,
            currentAmendedBudget1: String,
            currentAmendedBudget2: String,
            currentAmendedBudget3: String,
            currentAmendedBudget4: String,
            currentAmendedBudget5: String,
            priorBudgetRequest: String,
            priorIssueAndDollar: String,
            priorAmendedBudget1: String,
            priorAmendedBudget2: String,
            priorAmendedBudget3: String,
            priorAmendedBudget4: String,
            priorAmendedBudget5: String
        },
        methods: {
            getContactName: function (contact) {
                return contactFullName(contact);
            }
        },
        setup(props) {

            // Generate a unique ID for each instance
            const uniqueId = computed(() => {
                return uuidv4();
            });

            const path = [{
                    title: props.name
            }];
            const {user} = useUser();
            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);
            const vendorFlyoutRef = ref(null);
            const contractFlyoutRef = ref(null);
            const showme = ref(0);

            const vendorProps = ref(null);

            const { bus } = useEventsBus();

            const contractProps = ref(null);

            watch( () => bus.value.get('call-open-flyout-contract'),
                async (val) => {
                    contractProps.value = null;
                    if (contractFlyoutRef.value) {
                        console.log(contractFlyoutRef.value);
                        try {
                            const contractRef = await contractFlyoutRef.value.openFlyout(val[0]);
                            contractProps.value = contractRef;
                            var x = document.getElementById("snackbar-vendor-contract");
                            x.classList.add('show');
                            x.classList.add('fadein');
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            );

            watch( () => bus.value.get('call-open-flyout-vendor'),
                async (val) => {
                    vendorProps.value = null;
                    if (vendorFlyoutRef.value) {
                        try {
                            const vendorRef = await vendorFlyoutRef.value.openFlyoutVendor(val[0]);
                            vendorProps.value = vendorRef;
                            var x = document.getElementById("snackbar-vendor");
                            x.classList.add('show');
                            x.classList.add('fadein');
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            );

            return {
                user,
                path,
                showSearch,
                vendorFlyoutRef,
                contractFlyoutRef,
                vendorProps,
                contractProps,
                uniqueId,
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/mixins/_bemify.scss";
@import "../../assets/scss/helpers/responsive-tables";

    .filter-wrapper {
        max-width: 50%;
        margin: auto;
        margin-top: 20px;
        @media screen and (max-width: 768px) {
            width: 90%;
            max-width: 90%;
        }
        .search-block {
            margin-bottom: 30px;
        }
    }
table {
    margin: auto;
    display: inline-table;

    th {
        color: $white;
        background: $blue--dark;
        background: $blue-gradient;
    }

    tfoot td {
        background-color: $grey--light;
    }

    td, th {
        padding: 10px;
        border: solid 1px $grey--dark;
    }
}

    .max-w-100 {
        max-width: 100%;
    }
    .agency-page .container .columns.multi-cols .column .general-container {
        height: 100%;
    }
    .contracts-container {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .clickable {
        cursor: pointer;
    }
    .clickable span.tooltip {
        display: none;
        position: relative;
        border: 5px solid $blue--dark;
        &:after {
            bottom: 100%;
            left: 20%;
            border: solid transparent;
            content: " ";
            position: absolute;
        }
        &:after {
            border-bottom-color: $blue--dark;
            border-width: 15px;
        }
    }
    .procurements-container .column {
        margin-top: 50px;
        &.is-half-mobile {
            margin-top: 0px;
        }
    }
    .procurements-container--stats {
        margin-bottom: 30px;
    }
    .procurements-container--stats .column {
        cursor: pointer;
        text-align: center;
        padding: 15px;
        > div {
            box-shadow: 0 0.5em 1em -0.125em rgb(0 0 0 / 10%), 0 0 0 1px rgb(0 0 0 / 0%);
            border-radius: 6px;
            padding: 15px;
            background-color: #fff;
            &:hover {
                background-color: #f6f6f6;
            }
        }
    }
    .break-768 {
        .columns .column {
            @media screen and (max-width: 768px) {
                width: 100%;
                flex-basis: initial;
            }
        }
    }
    #tbl-keyword {
       height: 55px;
    }
</style>
