<template>
    <!-- Component: organisms/position-info-card -->
    <info-card :position="$props">
        <div class="card-content">

            <div class="section">
                <div class="content">

                <div class="media mobile-only">
                    <div class="media-right">
                        <figure class="image card__image">
                            <img :src="agency && agency.logo ? agency.logo.url : ''" :alt="agency && agency.logo ? agency.logo.alt : ''">
                        </figure>
                    </div>
                </div>

                    <p class="card__info__label">Position</p>
                    <h1 class="card__info__title">{{ title }}</h1>


                    <template v-if="displayTitle">
                        <p class="card__info__label">Position Title</p>
                        <p class="card__info__data">{{ displayTitle }}</p>
                    </template>

                    <p class="card__info__label mb-10">Agency</p>
                    <tagAgency
                        :tagText="agency ? agency.name : ''"
                        :tagUrl="agency ? agency.url : ''"
                    />

                    <p class="card__info__label mb-10">Budget Entity</p>
                    <tag-budget
                        :tagText="agency ? agency.name : ''"
                        :tagUrl="agency ? '/browse-budgets?agencyId=' + agency.contentId : ''"
                    />

                    <div class="columns">
                        <div class="column" v-for="program in programs" v-if="programs">
                            <p class="card__info__label card__info__label__with-tag">Program</p>
                            <tag-budget :tag-text="program.name" :tag-url="program.url"/>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column" v-if="positionNumber">
                            <p class="card__info__label">Position Number</p>
                            <p class="card__info__data">{{ positionNumber }}</p>
                        </div>

                        <div class="column" v-if="seniority">
                            <p class="card__info__label">Seniority</p>
                            <p class="card__info__data">{{ seniority }}</p>
                        </div>

                        <div class="column" v-if="payPlan">
                            <p class="card__info__label">
                                <Tooltip :tooltipText="definitions && definitions['Pay Plan'] && definitions['Pay Plan'].definition ? definitions['Pay Plan'].definition : 'Loading...'">
                                    <span class='has-tooltip'>
                                        Pay Plan
                                    </span>
                                </Tooltip>
                            </p>
                            <p class="card__info__data">{{ payPlan }}</p>
                        </div>

                        <div class="column" v-if="executiveAssistantContacts" v-for="(executiveAssistantContact, i) in executiveAssistantContacts">
                            <template v-if="executiveAssistantContact">
                                <p class="card__info__label mb-5" v-if="executiveAssistantContact.agencyHead == true" >Executive Assistant To</p>
                                <p class="card__info__label mb-5" v-else>Executive Assistant Role</p>
                                <tag-role :tagText="executiveAssistantContact.position.name" :tagUrl="executiveAssistantContact.url"  :leadership="true" v-if="executiveAssistantContact.agencyHead == true" />
                                <tag-role :tagText="executiveAssistantContact.position.name" :tagUrl="executiveAssistantContact.url" :leadership="false" v-else/>
                            </template>
                        </div>

                    </div>

                    <div class="columns">
                        <div class="column" v-if="flairOrganization">
                            <p class="card__info__label">
                                <Tooltip :tooltipText="definitions && definitions['FLAIR'] && definitions['FLAIR'].definition ? definitions['FLAIR'].definition : 'Loading...'">
                                    <span class='has-tooltip'>
                                        FLAIR
                                    </span>
                                </Tooltip>
                                 Org
                            </p>
                            <p class="card__info__data">{{ flairOrganization }}</p>
                        </div>

                        <div class="column" v-if="flairOrgCode">
                            <p class="card__info__label">
                                <Tooltip :tooltipText="definitions && definitions['FLAIR'] && definitions['FLAIR'].definition ? definitions['FLAIR'].definition : 'Loading...'">
                                    <span class='has-tooltip'>
                                        FLAIR
                                    </span>
                                </Tooltip>
                                 Org Code
                            </p>
                            <p class="card__info__data">{{ flairOrgCode }}</p>
                        </div>

                        <div class="column" v-if="payPlanCode">
                            <p class="card__info__label">Pay Play Code</p>
                            <p class="card__info__data">{{ payPlanCode }}</p>
                        </div>
                    </div>

                </div>

                <div class="media desktop-only">
                    <div class="media-right">
                        <figure class="image card__image">
                            <img :src="agency && agency.logo ? agency.logo.url : ''" :alt="agency && agency.logo ? agency.logo.alt : ''">
                        </figure>
                    </div>
                </div>
            </div>

            <div class="section__alt" v-if="positionDescription">
                <p class="card__info__label">Position Description</p>
                <p class="card__info__description" v-html="positionDescription"></p>
            </div>

        </div>
    </info-card>
    <!-- End Component: organisms/position-info-card -->
</template>

<script>
    import { ref, computed } from "vue";
    import { useStore } from "vuex";
    import InfoCard from "./info-card";
    import TagAgency from "./tag--agency";
    import TagBudget from "./tag--budget";
    import TagRole from "./tag--role";
    import Tooltip from "./tooltip";

    export default {
        name: 'PositionInformationCard',
        components: {InfoCard, TagAgency, TagBudget, TagRole, Tooltip},
        props: [
            "title",
            "locationId",
            "contentId",
            "seniority",
            "positionNumber",
            "payPlan",
            "positionDescription",
            "agency",
            "programs",
            "displayTitle",
            'payPlanCode',
            'flairOrgCode',
            'flairOrganization',
            'executiveAssistantContacts',
            'executiveAssistantPositions'
        ],
        setup(props) {
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);

            return definitions;
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('card') {
        @include element('info') {
            @include element('label') {
                float: left;
                margin-right: 10px;
                width: 100%;
            }
        }
        @include element('image') {
            img {
                max-width: 200px;
                max-height: 200px;
            }
        }
        .media {
            display: contents;
        }
        .gov-tag {
            margin-bottom: 30px;
            display: flex;
            width: fit-content;
        }
    }
    .mb-10 {
        margin-bottom: 10px !important;
    }
    .mb-5 {
        margin-bottom: 5px !important;
    }
    @media screen and (min-width: 769px) {
        .card .media.mobile-only {
            display: none !important;
        }
    }
    @media screen and (max-width: 768px) {
        .card .media.desktop-only {
            display: none !important;
        }
    }
</style>

