<template>
    <!-- Component: atoms/app-button -->
    <template v-if="isBudget != 'false'">
        <button class="button" :class="{'button__tool': !!icon}">
            <img src="@/assets/svg/bookmark.svg" :alt="icon + ' icon'" v-if="icon === 'bookmark'"/>
            <img src="@/assets/svg/notes.svg" :alt="icon + ' icon'" v-else-if="icon === 'notes'"/>
            <img src="@/assets/svg/notification.svg" :alt="icon + ' icon'" v-else-if="icon === 'notification'"/>
            <img src="@/assets/svg/settings.svg" :alt="icon + ' icon'" v-else-if="icon === 'settings'"/>
            <slot>{{ buttonText }}</slot>
        </button>
    </template>
    <template v-else>
        <button class="button button--budget" :class="{'button__tool': !!icon}">
            <img src="@/assets/svg/bookmark.svg" :alt="icon + ' icon'" v-if="icon === 'bookmark'"/>
            <img src="@/assets/svg/notes.svg" :alt="icon + ' icon'" v-else-if="icon === 'notes'"/>
            <img  src="@/assets/svg/notification.svg" :alt="icon + ' icon'" v-else-if="icon === 'notification'" />
            <img src="@/assets/svg/settings.svg" :alt="icon + ' icon'" v-else-if="icon === 'settings'"/>
            <slot>{{ buttonText }}</slot>
        </button>
    </template>
    <!-- End Component: atoms/app-button -->
</template>

<script>
    export default {
        name: 'app-button',
        props: ['icon', 'isBudget'],
        data() {
            return {
                buttonText: "Default Button"
            }
        },
        computed: {
            budgetButtons() {
                let imageBig = this.image.substring(0, this.image.length - 10) + '200x200.jpg';
                return imageBig;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('button') {
        background: $blue--dark;
        background: $blue-gradient;
        border: none;
        border-radius: 6px;
        font-weight: 600;
        font-family: $header;
        color: $white;
        margin-left: 10px;
        &:focus, &:hover, &:active {
            color: $blue--lighter;
        }
        @include element('tool') {
            height: 50px;
            font-weight: 600;
            padding: 25px !important;
            img {
                margin-right: 15px;
            }
            @media screen and (max-width: 450px) {
                width: 100%;
                margin-bottom: 15px;
                margin-left: 0px;
            }
        }
        @include modifier('budget') {
            background-color: transparent;
            color: $blue--dark;
            &:hover, &:focus, &:active {
                color: $blue--dark;
                text-decoration: underline;
            }
        }
    }
</style>
