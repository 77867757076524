<template>
    <!-- Component: molecules/masthead -->
    <div class="masthead" id="top">
        <div class="container mobile-container logo-container" role="region" aria-label="Utility menu">
<!--
            <router-link :to="{'name': 'dashboard'}" class="masthead__logo" v-if="isMobile() == false">
                <img src="@/assets/svg/MGG-logo-horiz-white.png" alt="MyGovGuide" class="logo-image no-print"/>
                <img src="@/assets/svg/MGG-logo-horiz.png" alt="MyGovGuide" class="logo-image print-only"/>
            </router-link>
            <a href="/dashboard" class="logo">-->
            <img :src="require('@/assets/img/masthead-background.png')" class="masthead__background" alt="background lines"/>
            <!--</a>-->

            <div class="masthead__profile__container" v-if="user">
				<div class="masthead__profile" v-if="user.image">
                    <Tooltip tooltipText="My Profile" position="bottom" cClass="tooltip--small-width">
                        <a href="/profile" class="profile-icon custom" aria-label="My Profile">
                            <span :style="'background-image: url(' + user.image.imageUrl + ')'"></span>
                            <span class="aria-hidden">Profile Image</span>
                        </a>
                    </Tooltip>
                    <a href="/logout" class="masthead__link" @click.prevent="logoutClick">Logout</a>
                </div>

                <div class="masthead__profile" v-else>
                    <Tooltip tooltipText="My Profile" position="bottom" cClass="tooltip--small-width">
                        <a href="/profile" class="profile-icon">
                            <img src="@/assets/svg/profile-default.svg" alt="Profile Icon" >
                        </a>
                    </Tooltip>
                    <a href="/logout" class="masthead__link" @click.prevent="logoutClick">Logout</a>
                </div>
            </div>

			<div class="masthead__profile__container" v-else>
				<div class="masthead__profile">
					<img src="@/assets/svg/profile-default.svg" alt="Profile Icon"/>
                    <router-link to="/login" class="masthead__link">Login</router-link>
				</div>
			</div>

            <div class="masthead__right" role="menubar">
                <template v-for="(link, k) in headerLinks" :key="'header-link-' + k">
                    <a class="mr-10" :href="link.href" role="menuitem">{{ link.name }}</a>
                </template>
            </div>
        </div>
    </div>
    <!-- End Component: molecules/masthead -->
</template>

<script>
    import useUser from "@/helpers/useUser";
    import { useRouter } from "vue-router";
    import Tooltip from "./tooltip";

    export default {
        name: 'Masthead',
        components: {
            Tooltip
        },
        mounted() {
            if (this.user && this.user?.image !== null) {
                let img = {
                    'src': encodeURI(user?.image.imageUrl),
                    'height': user?.image.imageHeight,
                    'width': user?.image.imageWidth
                };
            }
        },
        methods: {
            isMobile() {
                if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (window.innerWidth < 992)) {
                    return true
                } else {
                    return false
                }
            }
        },
        props: ['headerLinks',],
        setup() {
            const router = useRouter();
            const {logout, user} = useUser();
            const logoutClick = () => {
                logout().then(() => router.push({'name': 'login'}));
            };

            return {logoutClick, user};
        }
    }
</script>


<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .profile-icon {
        &:focus {
            border: 1px dotted $blue--light;
        }
        &.custom span {
            width: 40px;
            height: 40px;
            display: inline-block;
            border-radius: 50%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .aria-hidden {
        display: none !important;
        font-size: 0;
    }

    @include block('masthead') {
        background-color: $blue--dark;
        height: 56px;
        @include element('logo') {
            color: $white;
            font-family: $header;
            font-weight: 700;
            font-size: 32px;
            text-decoration: none;
            position: absolute;
            z-index: 9;
            @media screen and (max-width: 550px) {
                position: inherit;
                width: 100%;
                display: block;
            }
            &:hover, &:active, &:focus {
                color: $blue--lighter;
            }
            &:focus {
                border: 1px dotted $blue--light;
            }
            @include element('link') {
                color: $white;
            }
        }
        @include element('profile') {
            float: right;
            margin-top: 5px;
            @include element('container') {
                @media screen and (max-width: 550px) {
                    float: left;
                    margin-left: 15px;
                }
            }
        }
        @include element('right') {
            float: right;
            margin-top: 5px;
            margin-right: 20px;
            margin-top: 13px;
            a {
                color: white;
                transition: color .2s ease-in-out;
                &:hover, &:active, &:focus {
                    color: $grey--lighter;
                }
            }
        }
        @include element('background') {
            position: absolute;
            left: -100px;
        }
        @include element('link') {
            color: $white;
            position: relative;
            top: -15px;
            margin-left: 5px;
            &:hover, &:focus, &:active {
                color: $grey--light;
            }
        }
        @media screen and (max-width: 550px) {
            .masthead__profile__container {
                display: inline-block;
            }
            .masthead__background {
                display: none;
            }
        }
    }
</style>
