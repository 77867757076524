<template>

    <vendor-flyout
        ref="vendorFlyoutRef"
        v-bind="vendorProps"
    />

    <contract-flyout
        ref="contractFlyoutRef"
        v-bind="contractProps"
        :vendor="contractProps && contractProps.vendor && contractProps.vendor.VendorRef ? contractProps.vendor.VendorRef : null"
        :agency="contractProps && contractProps.agency && contractProps.agency.AgencyRef ? contractProps.agency.AgencyRef : null"
    ></contract-flyout>

    <!-- Component: page/program -->
    <div class="page-container">

        <br/>

        <breadcrumbs :items="path"/>

        <div class="container">
            <alert/>
        </div>

        <hr/>

        <div class="container" style="display: flow-root;" v-if="user">
            <userTools/>
        </div>

        <br/>

        <h1>{{ name }}</h1>

        <div class="container">
            <card-tools :program="$props" :view="'full'" :customClass="'budget'"/>
        </div>

        <br>

        <div class="container">
            <div class="columns">
                <div class="column height mb-30">

                    <infoBlock :hasIcon="false" :hasClose="false" :isTweet="false">
                        <p class="general-container__title general-container__title--sub has-text-centered">Budget Snapshot</p>
                        <div class="level-item has-text-centered bold">
                            <p>${{ appropriationAmount ? priceFormat(appropriationAmount) : 0 }} - Total
                                <Tooltip :tooltipText="definitions && definitions.Appropriation && definitions.Appropriation.definition ? definitions.Appropriation.definition : 'Loading...'">
                                        <span :class="definitions && definitions.Appropriation && definitions.Appropriation.definition ? 'has-tooltip' : ''">
                                            Appropriations
                                        </span>
                                </Tooltip>
                            </p>
                        </div>

                        <div class="miniBar large">
                            <div :class="'miniBarProgress spent '" :style="'left: 0; width: ' + percent + '%; background-color: gray;'" v-if="percent > 0"><span>{{ percent }}%</span></div>
                            <div :class="'miniBarProgress remains ' + checkAmount(percent)" :style="'left: ' + percent + '%; width: ' + (100 - percent) + '%; background-color: red;'"><span>{{ 100 - percent + '%' }}</span></div>
                        </div>

                        <nav class="level progress-level">
                            <div class="level-item has-text-centered">
                                <div>
                                    <Tooltip :tooltipText="'Year to date spending.'">
                                        <p class="heading3 has-tooltip">Amount Spent</p>
                                    </Tooltip>
                                    <p>
                                        ${{ disbursementAmount ? priceFormat(disbursementAmount) : 0 }}
                                        ({{
                                            appropriationAmount ? (Math.round(
                                                disbursementAmount / appropriationAmount * 100
                                            )) : 0
                                        }}%)
                                    </p>
                                </div>
                            </div>
                            <div class="level-item has-text-centered">
                                <div>
                                    <span class="slash budget-slash">/</span>
                                </div>
                            </div>
                            <div class="level-item has-text-centered">
                                <div>
                                    <p class="heading3">
                                        <Tooltip :tooltipText="definitions && definitions.Encumbered && definitions.Encumbered.definition ? definitions.Encumbered.definition : 'Loading...'">
                                            <span
                                                :class="definitions && definitions.Encumbered && definitions.Encumbered.definition ? 'has-tooltip' : ''">
                                                Encumbered
                                            </span>
                                        </Tooltip>
                                    </p>
                                    <p>
                                        ${{ encumbrancesAmount ? priceFormat(encumbrancesAmount) : 0 }}
                                        ({{
                                            appropriationAmount ? (Math.round(
                                                encumbrancesAmount / appropriationAmount * 100
                                            )) : 0
                                        }}%)
                                    </p>
                                </div>
                            </div>
                            <div class="level-item has-text-centered">
                                <div>
                                    <span class="slash budget-slash">/</span>
                                </div>
                            </div>
                            <div class="level-item has-text-centered">
                                <div>
                                    <p class="heading3">Budget Remaining</p>
                                    <p>
                                        ${{
                                            disbursementAmount ? priceFormat(
                                                appropriationAmount - encumbrancesAmount - disbursementAmount
                                            ) : 0
                                        }}
                                        ({{
                                            appropriationAmount ? (Math.round(
                                                (appropriationAmount - encumbrancesAmount - disbursementAmount)
                                                / appropriationAmount * 100
                                            )) : 0
                                        }}%)
                                    </p>
                                </div>
                            </div>
                        </nav>
                    </infoBlock>
                </div>

            </div>
        </div>

        <div class="container">
            <div class="columns">
                <div class="column height">
                    <interested-in-block
                        :title="'Notable Budget Updates'"
                        :readMoreOverride="'See All Recent Budget Updates'"
                        :num="1" :type="'budget_update'"
                        :subtree="locationId"
                        :customClass="'mb-30'"
                    >
                    </interested-in-block>
                </div>
                <div class="column height">
                    <general-container
                        :customClass="'column'"
                        :title="'Related Contact' + (leadership.length != 1 ? 's' : '')"
                        :isLocked="false"
                    >
                        <contact-line
                            v-for="leader in leadership"
                            :name="getContactName(leader)"
                            :agency="leader.position.agency.name"
                            :role="leader.position.name + (leader.positionRole ? ' (' + leader.positionRole + ')' : '')"
                            :url="leader.url"
                            :agencyUrl="agency.url"
                            :roleUrl="leader.position.url"
                            :leadership="leader.agencyHead"
                            :key="leader.position.name"
                            :imagePath="leader.photo ? leader.photo.url : null"
                        />
                        <button-link :href="relatedContactsLink" :target="'_self'" :contents="'See All Related Contacts'"/>
                    </general-container>
                </div>
            </div>
        </div>

        <program-budget-history-block
            :historical="historical"
        />

        <div class="container" v-if="appropriationCategories && appropriationCategories.length && showlistafter">
            <div class="columns no-flex__container">
                <div class="column">
                    <container-search v-if="isTreemapDetailVisible"
                                      :search-data="appropriationCategories"
                                      :name="'Budget Details'"
                                      :message="'NOTE: This search returns budget details within ' + name + '.'"
                    >
                    </container-search>

                    <general-container
                        :customClass="'column'"
                        :titleClass="'has-text-centered'"
                        :isLocked="false"
                        :title="'Budget Details'"
                        :titleTooltip="true"
                        :ttText="'How the money is divided up and being spent in the current fiscal year.'"
                    >
                        <div class="height-restricted">
                            <template
                                v-if="appropriationCategories && appropriationCategories.length"
                                v-for="appropriationCategory in appropriationCategoriesSorted"
                                :key="appropriationCategory.contentId"
                            >

                                <div class="info-block">

                                    <div class="program-label">
                                        <span v-on:click="priceFormat(appropriationCategory.disbursementAmount) !== '0' ? {click: emit('call-link-to-treemap', [appropriationCategory]) } : {}" :class="priceFormat(appropriationCategory.disbursementAmount) !== '0' ? 'active-program' : ''" :data-name="appropriationCategory.name.replace(/[^A-Z0-9]/ig, '_')">
                                            {{ appropriationCategory.name }}<br/>
                                            {{ appropriationCategory.fund }}
                                        </span>
                                    </div>

                                    <div class="total-apropriations-label">
                                        {{ '$' + priceFormat(appropriationCategory.appropriationAmount) + ' - Total Appropriations' }}
                                    </div>

                                    <div v-if="appropriationCategory.isVeto" class="has-text-centered veto">
                                        <img class="veto__icon" src="@/assets/svg/thumbs-down.svg" alt="Veto icon" />
                                        <p class="veto__text">All or a portion of this budget item was vetoed.</p>
                                    </div>

                                    <template v-if="priceFormat(appropriationCategory.appropriationAmount) != 0">
                                        <div class="barWrapper mt-20">
                                            <div class="miniBar">
                                                <div :class="'miniBarProgress spent ' + checkAmount(getPC(appropriationCategory.disbursementAmount, appropriationCategory.appropriationAmount))" :style="'left: 0; width: ' + getPC(appropriationCategory.disbursementAmount, appropriationCategory.appropriationAmount) + '%; background-color: gray;'" v-if="getPC(appropriationCategory.disbursementAmount, appropriationCategory.appropriationAmount) > 0"><span>{{ getPC(appropriationCategory.disbursementAmount, appropriationCategory.appropriationAmount) }}%</span></div>
                                                <div :class="'miniBarProgress remains ' + checkAmount(getPC(appropriationCategory.disbursementAmount, appropriationCategory.appropriationAmount))" :style="'left: ' + getPC(appropriationCategory.disbursementAmount, appropriationCategory.appropriationAmount) + '%; width: ' + (100 - getPC(appropriationCategory.disbursementAmount, appropriationCategory.appropriationAmount)) + '%; background-color: red;'"><span>{{ 100 - getPC(appropriationCategory.disbursementAmount, appropriationCategory.appropriationAmount) + '%' }}</span></div>
                                            </div>
                                        </div>

                                        <nav class="level progress-level">
                                            <div class="level-item has-text-right">
                                                <div>
                                                    <Tooltip :tooltipText="'Year to date spending.'">
                                                        <p class="card__info__label has-tooltip mb-5">Amount Spent</p>
                                                    </Tooltip>
                                                    <p class="title">${{ priceFormat(appropriationCategory.disbursementAmount) + ' (' + getPC(appropriationCategory.disbursementAmount, appropriationCategory.appropriationAmount) + '%)' }}</p>
                                                </div>
                                            </div>
                                            <div class="level-item has-text-centered slash-container">
                                                <div>
                                                    <span class="slash t15">/</span>
                                                </div>
                                            </div>
                                            <div class="level-item has-text-left">
                                                <div>
                                                    <p class="card__info__label mb-5">Budget Remaining</p>
                                                    <p class="title">${{ priceFormat(appropriationCategory.appropriationAmount - appropriationCategory.disbursementAmount) + ' (' + (100 - getPC(appropriationCategory.disbursementAmount, appropriationCategory.appropriationAmount)) + "%)" }}</p>
                                                </div>
                                            </div>
                                        </nav>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </general-container>
                </div>
            </div>
        </div>

        <div class="container" v-if="isTreemapDetailVisible">
            <SpendingDetailsBlock :locationId="locationId" :name="name" :dontShowTreemapSearch="dontShowTreemapSearch" ref="spendingDetailsBlockRef"/>
        </div>

        <div class="container po">
            <div class="columns">
                <div class="column">
                   <organization-allotments-table
                       :definitions="definitions"
                        :location-id="locationId"
                   />
                </div>
            </div>
        </div>

        <div class="container contracts-container" v-if="contentId">
            <div class="columns">
                <div class="column">
                    <contracts-table
                        :uniqueId="uniqueId"
                        :key="contentId"
                        :program-id="contentId"
                        :search-agency="false"
                        :search-type="true"
                        :search-fiscal="true"
                        title="Program Contracts"
                        title-class="has-text-centered"
                        :subflyout="true"
                        :vendorshow="true"
                    />
                </div>
            </div>
        </div>
    </div>
    <!-- Component: page/program -->
</template>

<script>
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "./breadcrumbs";
    import UserTools from "@/alivue/components/user-tools";
    import GeneralContainer from "./general-container"
    import ContainerSearch from "./container-search";
    import AgencyBudgetsHistoryChart from "./agency-budgets-history-chart";
    import InfoBlock from "./info-block"
    import SearchBlock from "./search-block";
    import { computed, onMounted, ref, watch } from "vue";
    import { priceFormat } from "@/helpers/filter";
    import Preloader from "@/alivue/components/preloader";
    import { useStore } from "vuex";
    import useUser from "@/helpers/useUser";
    import StateBudgetChart from "./state-budget-chart";
    import InterestedInBlock from '@/alivue/components/interested-in-block.vue';
    import ContactLine from "@/alivue/components/contact-line";
    import ButtonLink from "./button-link";
    import CardTools from "./card-tools";
    import ContractsTable from "./contracts-table";
    import { contactFullName } from "@/helpers/objects";
    import Tooltip from "./tooltip";
    import Pagination from "./pagination.vue";
    import VendorFlyout from "./vendor-flyout";
    import ContractFlyout from "./contract-flyout";
    import ProgramBudgetHistoryBlock from "./program-budget-histori-block.vue";
    import OrganizationAllotmentsTable from "./organization-allotments-table.vue";
    import SpendingDetailsBlock from "./spending-details-block.vue";
    import { useEventsBus } from "@/helpers/eventBus";
    import { scrollToTile, clickToTile } from "@/helpers/budgetTreeMap";
    import { v4 as uuidv4 } from "uuid";

    export default {
        name: 'Program',

        props: [
            'agency',
            'category',
            'contentId',
            'locationId',
            'name',
            'keyAnalysis',
            'appropriationAmount',
            'unbudgetedAmount',
            'approvedAmount',
            'releasedAmount',
            'unreleasedAmount',
            'disbursementAmount',
            'encumbrancesAmount',
            'disbursementCategories',
            'leadership',
            'disbursementCategoryGroupTotals',
            'historical',
            'relatedContactsLink',
            'appropriationCategories',
        ],
        components: {
            ProgramBudgetHistoryBlock,
            OrganizationAllotmentsTable,
            SpendingDetailsBlock,
            ContractsTable,
            Preloader,
            InfoBlock,
            GeneralContainer,
            ContainerSearch,
            Alert,
            Breadcrumbs,
            SearchBlock,
            StateBudgetChart,
            UserTools,
            AgencyBudgetsHistoryChart,
            InterestedInBlock,
            ContactLine,
            ButtonLink,
            CardTools,
            Tooltip,
            Pagination,
            VendorFlyout,
            ContractFlyout,
        },
        methods: {
            dontShowTreemapSearch() {
                this.isTreemapDetailVisible = false;
            },
            getPC(one, two) {
                if ((one / two) > 1) {
                    return 100;
                } else {
                    return Math.round(one / two * 100);
                }
            },
            getContactName: function (contact) {
                return contactFullName(contact);
            },
            getDisbursementCategoryPercent(field) {
                if (typeof this.disbursementCategoryGroupTotals[field] === 'undefined' || this.disbursementAmount === 0) {
                    return 0;
                }
                let percent = this.disbursementCategoryGroupTotals[field] / this.disbursementAmount;
                return (percent * 100).toFixed(1);
            },
            titleize: function(item) {
                return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
            },
            checkAmount(amount) {
                if (amount == 100) {
                    return ' full';
                } else if (amount == 0) {
                    return ' empty';
                };
            },
            getNames(obj, savedAppropriationCategoryName) {
                if (!obj) return [];

                return obj.map(el => {
                    if (!el) {
                        return;
                    }

                    if (
                        typeof savedAppropriationCategoryName === "undefined" &&
                        el.contentType === "Appropriation Category"
                    ) {
                        savedAppropriationCategoryName = el.name;
                    }

                    const names = [{
                        'name' : el.name,
                        'locationId' : el.locationId,
                        'appropriationCategoryName' : savedAppropriationCategoryName,
                    }];

                    if (el.children) {
                        names.push(this.getNames(el.children, savedAppropriationCategoryName));
                    }

                    return names;
                }).flat(Infinity);

            },
        },
        computed: {
            appropriationCategoriesSorted: function() {
                function compare(a, b) {
                    if (a.appropriationAmount > b.appropriationAmount) {
                        return -1;
                    }
                    if (a.appropriationAmount < b.appropriationAmount) {
                        return 1;
                    }

                    return 0;
                }
                return this.appropriationCategories.sort(compare);
            }
        },
        setup(props) {
            const showlistafter = ref(false)
            const isTreemapDetailVisible = ref(true);
            const { user } = useUser();
            const chartEl = ref(null);
            const percent = ref(Math.round((props.disbursementAmount / props.appropriationAmount) * 100));
            const budgetCategoriesChartEl = ref(null);
            const budgetCategoriesLegendEl = ref(null);
            const categoriesTreeLoading = ref(true);
            const categoriesTree = ref(null);

            const vendorFlyoutRef = ref(null);
            const contractFlyoutRef = ref(null);
            const spendingDetailsBlockRef = ref(null);
            const vendorProps = ref(null);
            const contractProps = ref(null);

            const { bus } = useEventsBus();

            const { emit } = useEventsBus();

            watch( () => bus.value.get('call-open-flyout-contract'),
                async (val) => {
                    contractProps.value = null;
                    if (contractFlyoutRef.value) {
                        console.log(contractFlyoutRef.value);
                        try {
                            const contractRef = await contractFlyoutRef.value.openFlyout(val[0]);
                            contractProps.value = contractRef;
                            var x = document.getElementById("snackbar-vendor-contract");
                            x.classList.add('show');
                            x.classList.add('fadein');
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            );

            watch( () => bus.value.get('call-open-flyout-vendor'),
                async (val) => {
                    vendorProps.value = null;
                    if (vendorFlyoutRef.value) {
                        try {
                            const vendorRef = await vendorFlyoutRef.value.openFlyoutVendor(val[0]);
                            vendorProps.value = vendorRef;
                            var x = document.getElementById("snackbar-vendor");
                            x.classList.add('show');
                            x.classList.add('fadein');
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            );
            watch(() => bus.value.get('render-done'), ()=> showlistafter.value = true)

            watch( () => bus.value.get('call-link-to-treemap'),
                async (val) => {
                    if (spendingDetailsBlockRef.value) {
                        try {
                            clickToTile(val[0][0], spendingDetailsBlockRef.value.getTreemap(), val[0][1]);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            );

            watch(isTreemapDetailVisible, (newValue) => {
                if (!newValue) {
                    // The value is now false, you can perform additional logic here if needed
                    console.log("hideMe element should be hidden now");
                }
            });

            onMounted(() => {
                let budgetHistoryData = props.historical;
                function calculateCurrentFiscalYear() {
                    let currentYear = (new Date()).getFullYear();
                    let currentMonth = (new Date()).getMonth();
                    if (currentMonth < 6) {
                        return currentYear - 1;
                    }
                    return currentYear;
                }
                let currentFiscalYear = calculateCurrentFiscalYear();
                if (budgetHistoryData.length && budgetHistoryData[0].fiscalYearFrom !== currentFiscalYear) {
                    let year = currentFiscalYear;
                    budgetHistoryData.unshift({
                        fiscalYearFrom: year,
                        appropriationAmount: props.appropriationAmount,
                        disbursementAmount: props.disbursementAmount,
                    });
                }
            });

            // Generate a unique ID for each instance
            const uniqueId = computed(() => {
                return uuidv4();
            });

            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);

            const path = [{ title: props.agency.name, url: props.agency.url }];
            path.push({
                url: '/browse-budgets?agencyId=' + props.agency.contentId,
                title: 'Budget',
                isActive: false,
            });
            if (props.category) {
                path.push({ title: props.category.name, url: props.category.url });
            }
            path.push({ title: props.name, isActive: true });

            const definitions = computed(() => store.getters.definitions);

            return {
                isTreemapDetailVisible,
                chartEl,
                showSearch,
                path,
                priceFormat,
                scrollToTile,
                percent,
                user,
                definitions,
                parseInt,
                budgetCategoriesChartEl,
                budgetCategoriesLegendEl,
                categoriesTree,
                categoriesTreeLoading,
                vendorFlyoutRef,
                contractFlyoutRef,
                vendorProps,
                contractProps,
                spendingDetailsBlockRef,
                uniqueId,
                emit,
                showlistafter,
            };
        }
    }
</script>

<style lang="scss">
    ///
    /// these styles have been non-scoped for a really long time so i am hesitant to scope these in. for scoped styles see tag below
    ///
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
    @import "../../assets/scss/helpers/responsive-tables";

    .treemap-container {
        //max-width: 600px;
        position: relative;
        background-color: white;
        padding: 15px;
    }

    .hide-text + clipPath + text {
        //display: none;
        // we cannot use 'display: none' because it will hide the clip path and break anchor links from the thermo program cards above. using 'font-size: 0' instead
        font-size: 0;
    }

    .chart-legend {
        text-align: left;
    }

    .chart-legend {
        span {
            font-size: 2.4em;
            padding-right: 10px;
            line-height: 10px;
        }
    }
    .legend-item-type {
        color: $blue--dark;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 400;
        font-size: 11px;
        letter-spacing: 1px;
        font-family: Montserrat, sans-serif;
    }
    .legend-item-title {
        color: $blue--dark;
        font-size: 14px !important;
        font-weight: normal !important;
    }
    .legend-item-amount {
        color: $blue--dark;
        font-weight: bold;
    }
    .treemap-tooltip {
        position: absolute;
        background-color: $white;
        border: 2px solid $blue--dark;
        border-radius: 4px;
        padding: 5px;
        transform: translateX(-50%);
        text-align: center;
        width: 200px;
        z-index: 1;
        div {
            font-weight: bold;
        }
        .treemap-tooltip-type {
            color: $blue--dark;
            font-size: 16px;
        }
        .treemap-tooltip-title {
            color: $grey--dark;
            font-size: 14px;
            font-weight: normal;
        }

        .treemap-tooltip-close {
            background-color: $white;
            border: none;
            cursor: pointer;
            float: right;

            &:hover, &:focus {
                text-decoration: underline;
            }
        }

        &:hover, &:active, &:focus {
            display: block !important;
        }
        &:after {
            position: absolute;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            left: 50%;
            top: -6px;
            width: 0;
            height: 0;
            border-color: transparent transparent $blue--dark;
            border-style: solid;
            border-width: 0 5px 5px;
            content: "";
        }
    }

    #view-vendor-link:not(.disabled), #treemap-tooltip-clone:not(.disabled) {
        &:hover, &:active, &:focus {
            text-decoration: underline;
        }
    }

    tspan[underline*="true"] {
        text-decoration: none;
        tspan#underline {
            text-decoration: underline;
        }
    }

    @include block('agency-budget') {
        @include element('total-budget') {
            font-weight: 600;
            color: $blue--dark;
            font-size: 18px;
        }
    }

    .heading {
        font-weight: 600;
        color: $blue--dark;
        font-size: 18px;
        text-transform: none !important;
    }

    .heading2 {
        font-weight: 600;
        margin-bottom: 0 !important;
    }

    .heading3 {
        color: $grey--dark;
        margin-bottom: 0 !important;
    }

    .total {
        font-weight: bold;
    }

    .total-1 {
        text-align: right;
    }

    .slash {
        font-size: 42px;
        &.t15 {
            position: relative;
            top: 15px;
        }
        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .budget-chart {
        margin-bottom: 30px;
        width: 50%;
        height: 50%;
    }

    .left-arrow::before {
        content: "←";
    }

    table {
        margin: auto;
        display: inline-table;

        th {
            color: $white;
            background: $blue--dark;
            background: $blue-gradient;
            border-top: 0!important; // this prevents a sliver of text from appearing above the table heads when the table cells below are scrolled
        }

        tfoot td {
            background-color: $grey--light;
        }

        td, th {
            padding: 10px;
            border: solid 1px $grey--dark;
        }
        &.zebra {
            width: 100%;
        }
    }

    .zebra tr:nth-child(even) {
        //background: #eee;
    }

    .zebra tr:nth-child(odd) {
        background-color: $grey--light;
    }

    .chart1, .chart2, .contracts-container {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .tbl-scrollable {
        max-height: 90vh;
        overflow-y: auto;

        table {
            width: 100%;
            background-color: $white;
        }

        thead th {
            color: $white;
            position: sticky;
            top: 0;
        }

        tfoot td {
            position: sticky;
            bottom: 0;
        }
    }

    .bar-chart-container {
        max-height: 50vh;
        overflow-y: auto;
    }

    .bar-chart-tooltip {
        position: absolute;
        background-color: $white;
        border: 2px solid $blue--dark;
        border-radius: $border-radius;
        padding: 5px;

        &:hover, &:active, &:focus {
            display: block !important;
        }
    }

    .clickable {
        cursor: pointer;
        &.has-tooltip-hover {
            span.word {
                border-bottom: 1px dashed $white;
            }
            span.tooltip {
                border-bottom: none;
            }
        }
    }

    .columns.no-flex__container {
        display: block !important;
    }

    .programBudgetHistoryLegendEl.treechart-legend-container {
        margin-top: 10px;
        > svg {
            display: none;
        }
    }

    .slash {
        font-size: 42px;
    }

    .level-item .title {
        font-size: 1rem;
        color: $blue--dark;
    }

    .mt-20 {
        margin-top: 20px !important;
    }

    .height-restricted {
        max-height: 605px;
        scroll-behavior: smooth;
        margin-bottom: 50px;
        overflow: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar:vertical {
            width: 11px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 2px solid $blue--lighter; /* should match background, can't be transparent */
            background-color: rgba(0, 0, 0, .5);
        }

    }

    .slash-container {
        position: relative;
        top: 5px;
    }

    @media screen and (max-width: 768px) {
        .slash-container {
            display: none;
        }
        .level-item .title {
            margin-bottom: 10px !important;
        }
    }

    .card__info__label.mb-5 {
        margin-bottom: 5px !important;
    }

    .budget-slash {
        @media screen and (min-width: 769px) {
            padding-left: 10px;
            padding-right: 10px;
            position: relative;
            top: -5px;
        }
    }

    .total-apropriations-label {
        text-align: center;
        font-weight: bold;
        font-size: 16px;
    }

    .program-label {
        text-align: center;
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 18px;
        .active-program {
            color: $blue--med;
            text-decoration: underline;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            &:hover, &:focus, &:active {
                color: $blue--dark;
            }
        }
    }

    .general-container__title.general-container__title--sub {
        margin-bottom: 0.5em !important;
    }
    .level-item.has-text-centered.bold {
        margin-bottom: 0.5em;
        font-weight: bold;
    }

    /* Animations to fade the snackbar in and out */
    @-webkit-keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }

    @keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }

    @-webkit-keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }

    @keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }

    .close-item:before {
        font-size: 30px;
        cursor: pointer;
        content: "×";
        color: $blue--dark;
        transition: color .3s;
        font-weight: 600;
        margin-left: 10px;
        position: relative;
        z-index: 10;
        float: right;
        top: -5px;
        left: -5px;
    }

    // Bulma Steps overrides
    .steps .legend-item {
        cursor: pointer;
        &:hover {
            opacity: 0.6;
        }
    }
    .steps .steps-marker {
        height: 1.5rem !important;
        width: 1.5rem !important;
        top: -10px !important;
        border: 3px solid $blue--lighter !important;
    }
    .steps.is-vertical .steps-segment:not(:last-child):after {
        left: calc(1rem - 0.35em) !important;
        top: 0.76rem !important;
    }
    .steps:not(.is-hollow) .steps-marker:not(.is-hollow),
    .steps:not(.is-hollow) .steps-segment.is-active .steps-marker:not(.is-hollow),
    .steps-segment:after {
        background-color: $blue--med !important;
    }
    .steps.is-vertical .steps-segment:not(:last-child):after {
        width: 0.2em !important;
    }
    .steps.is-vertical .steps-content {
        margin-left: 1rem !important;
        margin-bottom: 1.5em !important;
    }
    .steps.is-vertical.is-dashed .steps-segment:after, .steps.is-vertical .steps-segment.is-dashed:after {
        background: repeating-linear-gradient(0deg, $blue--light, $blue--light 5px, transparent 0, transparent 10px) !important;
    }
    @media screen and (max-width: 768px) {
        .treemap-wrapper-container > .column{
            width: 100% !important;
        }
    }
    .clickable span.tooltip {
        display: none;
        position: relative;
        border: 5px solid $blue--dark;
        &:after {
            bottom: 100%;
            left: 20%;
            border: solid transparent;
            content: " ";
            position: absolute;
        }
        &:after {
            border-bottom-color: $blue--dark;
            border-width: 15px;
        }
    }
    .clickable:hover span.tooltip {
        position: absolute;
        top: 45px;
        left: 0;
        display: inline;
        background-color: $blue--dark;
        color: $white;
        padding: 5px;
        font-weight: 400;
        border-radius: 4px;
    }
    .treemap-icon, .treemap-icon--stop {
        transform: scale(.05) translate(200px, 200px);
    }
    .treemap-icon--stop {
        display: none;
    }
    .treemap-container svg g g:last-of-type .treemap-icon,
    .hide-text ~ .treemap-icon {
        display: none;
    }
    .treemap-container svg g g:not([cursor="pointer"]) {
        .treemap-icon {
            display: none;
        }
        .treemap-icon--stop {
            display: block;
        }
    }
    .treemap-container svg g g:last-of-type .treemap-icon--stop {
        display: none !important;
    }
    .toggle {
        margin-left: 15px;
    }
    .view-type-container {
        margin-bottom: 30px;
        text-align: right;
    }
    .view-toggle img {
        max-width: 24px;
    }
    .toggle__title {
        margin-right: 10px;
    }
    @include block('veto') {
        @include element('text') {
            display: inline-block;
            color: $red;
            margin-bottom: 0;
        }
        @include element('icon') {
            display: inline-block;
            margin-right: 10px;
            max-width: 25px;
            position: relative;
            top: 7px;
        }
    }

    .pagination-container {
        margin-bottom: 10px;
    }
    #tbl-keyword {
       height: 55px;
    }
</style>


<style lang="scss" scoped>
    ///
    /// if any of the above classes are interfering with other templates, move them into the scope of this template below
    ///
    .columns {
        display: flex;
        align-items: left;
        justify-content: left;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row wrap;
        align-content: flex-end;

        .height.column {
            display: inline-block;
            width: 100%;
    //            flex: 0 50%;
            margin: 0;
            @media screen and (max-width: 1215px) {
                flex: 0 100%;
                margin: 0;
            }

            &.mb-30 {
                margin-bottom: 30px;
            }

            > .info-block, > .general-container {
                height: 100%;
            }
        }
    }
</style>