<template>
    <!-- Component: organisms/turnover-chart -->

    <general-container title="Agency Turnover" :customClass="'agency-turnover-chart'">

        <preloader v-if="loading"></preloader>

        <template v-if="!loading" v-for="(item, k) in sortedArray()">

            <template v-if="item.in ==0 && item.out == 0">
                <!-- making this empty so we don't render anything at all -->
            </template>

            <template v-else-if="(item.out >= 0 || item.in >= 0)">
                <div class="title-description">
                    <span v-if="item.out >= 1" class="turnover-percentage">Total turnover rate: {{ ((item.out / item.total) * 100).toFixed(2) }}%</span>

                    <div class="inflow-outflow">
                        <span class="inflow">New employees: {{ item.in }}</span>
                        <span class="outflow">Employees leaving the agency: {{ item.out }}</span>
                    </div>
                </div>
                <div class="turnover-chart">

                    <div v-if="item.out >= 1" class="positive-negative-chart reverse both">
                        <!-- if we have any 'outflow' show this... -->
                        <div class="ext pos in" >
                            <Tooltip :tooltipText="item.in + ' new employee' + (item.in == 1 ? '' : 's')" >
                                <div class="bar right"
                                     :style="'width: ' + ( item.in/(item.in + item.out) * 80 ) + '%; max-width: 80%;'"
                                     @click="choise('to', item.agencyName)">

                                </div>
                            </Tooltip>
                        </div>
                        <div class="ext neg out" >
                            <Tooltip :tooltipText="item.out + ' employee' + (item.out == 1 ? '' : 's') + ' left this agency'" >
                                <div class="bar left"
                                     :style="'width: ' + ( item.out/(item.in + item.out) * 80 ) + '%; max-width: 80%;'"
                                     @click="choise('from', item.agencyName)">
                                </div>
                            </Tooltip>
                        </div>
                    </div>


                </div>
            </template>

        </template>

    </general-container>
    <!-- End Component: organisms/turnover-chart -->
</template>

<script>
import GeneralContainer from "./general-container"
import Tooltip from "./tooltip";
import RequestHandler from "@/handler/RequestHandler";
import Preloader from "./preloader";

export default {
    name: 'TurnoverChartIndividual',
    props: [
        'agencyId'
    ],
    components: {
        Tooltip,
        Preloader,
        GeneralContainer
    },
    mounted() {
        this.loadTurnover();
    },
    methods: {
        sortedArray(){
            return this.chartData.sort((a, b) => b.in - a.in );
        },
        allTurnoverCount(){
            let allTurnover = 0;
            const agencyArray = this.sortedArray();
            for ( let i = 0; i < agencyArray.length; i++ ) {
                if (agencyArray[i].total) {
                    allTurnover += (agencyArray[i].out / agencyArray[i].total) * 100;
                }
            }
            return allTurnover.toFixed(2);
        },
        loadTurnover() {
            this.loading = true;
                RequestHandler.loadTurnOver(this.$props.agencyId).then(d => {
                    this.chartData = d;
                    this.loading = false;
                    this.showAll = false;
                    this.error = false;
                }).catch(err => {
                    this.loading = false;
                    this.showAll = false;
                    this.error = true;
                    console.log(err);
                });
        }
    },
    watch: {
        agencyId() {
            this.loadTurnover();
        },
    },
    data() {
        return {
            loading: true,
            error: false,
            showAll: true,
            chartData: [],
            allAgencies: [],
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/mixins/_bemify.scss";

$green-bar: #179200;
$red-bar: #c51318;
#turnover {
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    max-width: 95vw; /* for mobile */
    margin-left: -125px; /* Divide value of min-width by 2 */
    padding: 0px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 9999; /* allows to display in front of nav */
    right: 15px;
    bottom: 35px;
    max-height: 90vh;
    overflow-y: scroll;
    background-color: $blue--lighter !important;
    color: $blue--dark !important;
    box-shadow: $box-shadow !important;
    border-radius: 8px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#turnover.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
    &.fadein {
        -webkit-animation: fadein 0.5s;
        animation: fadein 0.5s;
    }
    &.fadeout {
        -webkit-animation: fadeout 0.5s;
        animation: fadeout 0.5s;
    }
}
@include block('turnover-chart') {
    @include element('title') {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        width: 100%;
        margin-bottom: 5px;
        display: block;
    }
}

.positive-negative-chart {
    height: 30px;
    position: relative;
    text-align: center;
    clear: both;
    margin-bottom: 15px;
}

.positive-negative-chart.reverse {
    .ext {
        float: right;
    }
}

.positive-negative-chart::before {
    background-color: black;
    content: '';
    height: 110%;
    left: 50%;
    position: absolute;
    top: 0;
    width: 0.1vw;
    z-index: 1;
}

.ext {
    padding: 2px 0;
    text-align: center;
    width: 50%;
    float: left;
    margin-top: 5px;

    &.pos {
        .bar {
            background-color: $green-bar;
            background: linear-gradient(45deg,#46cb79,#136f63);
        }
    }
    &.neg {
        .bar {
            background-color: $red-bar;
            background: linear-gradient(120deg, #78041c, #bf0a30);
            margin-left: auto;
        }
    }
}

.both {
    .ext {

        &.pos {
            .left {
                float: right;
            }
            .right {
                float: left;
            }
        }
        &.neg {
            .left {
                float: right;
            }
            .right {
                float: left;
            }
        }
    }
}

.bar {
    height: 20px;
    &.fl {
        float:left;
    }
    &.fr {
        float:right;
    }
}

.turnover-chart {
    margin-bottom: 30px;
    &:last-of-type {
        padding-bottom: 30px;
    }
    .pos {
        [tooltip]:not([position]):after,
        [tooltip]:not([position]):before,
        [tooltip][position^=top]:after,
        [tooltip][position^=top]:before {
            left: auto !important;
        }
    }
    .neg {
        [tooltip]:not([position]):after,
        [tooltip]:not([position]):before,
        [tooltip][position^=top]:after,
        [tooltip][position^=top]:before {
            left: auto !important;
        }
        [tooltip]:not([position]):after,
        [tooltip][position^=top]:after {
            //			    position: relative;
            left: 100% !important;
            max-width: 200px;
            transform: translateY(-30px);
            top: -40px;
        }
        [tooltip]:not([position]):before,
        [tooltip][position^=top]:before {
            position: relative;
            left: 100% !important;
            max-width: 10px;
            top: -5px;
            transform: translateY(-30px);
        }
        > span:hover {
            position: relative;
            top: -5px;
        }
    }
}

.title-description {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.agency-turnover-legend {
    margin: 15px 30px;
    text-align: center;
    background-color: rgba(51,116,170,.1);
    border: 1px solid #3374aa;
    padding: 15px;
    p {
        margin-bottom: 0px;
    }
}
.turnover-percentage {
    font-size: 21px;
    color: $blue--med;
    font-weight: 600;
    display: block;
    text-align: center;
}

.inflow-outflow {
    text-align: center;
    .inflow, .outflow {
        font-size: 18px;
        font-weight: bold;
        display: block;
    }
    .inflow {
        color: $green-bar;
    }
    .outflow {
        color: $red-bar;
    }
}
</style>
